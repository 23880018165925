.logo-abbr{
    width: 3rem;
}
.rowed{
    display: flex!important;
    justify-content: space-between;
    padding-right: 1rem;
}
.col-sm{
    width: 2%;
    height: 10;
}
.col-sm-2{
    width: 1%;
    height: 10;
}
.flexy{
    display: flex;
}
/* Pagination container */
.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    margin-top: 20px;
  }
  
  /* Pagination button */
  .pagination-button {
    margin: 0 5px;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }
  
  /* Active page button */
  .active {
    background-color: #007bff;
    color: #fff;
  }
